



























import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import { PaymentMethodCode } from '@vf/api-client';
import type {
  ApplePayTranslations,
  CheckoutPaymentInfoTranslations,
} from '@vf/api-contract';
import CreditCardForm from '../../payment/CreditCardForm.vue';
import Klarna from '../../payment/Klarna.vue';
import PayPal from '../../payment/PayPal.vue';
import ApplePay from '../../payment/ApplePay.vue';
import { ApplePayContext, useApplePay } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import useLoader from '@/shared/useLoader';

export default defineComponent({
  name: 'CheckoutPaymentVariants',
  components: {
    CreditCardForm,
    Klarna,
    PayPal,
    ApplePay,
  },
  props: {
    paymentMethod: {
      type: String,
      default: '',
    },
    paymentCode: {
      type: String,
      default: '',
    },
    showCreditCardForm: {
      type: Boolean,
      default: false,
    },
    translations: {
      type: Object as PropType<CheckoutPaymentInfoTranslations>,
      required: true,
    },
    applePayTranslations: {
      type: Object as PropType<ApplePayTranslations>,
      required: true,
    },
    /** Maximum expiration year of the card */
    maxExpirationYear: {
      type: [Number, String],
      default: 2040,
    },
    /** Uri to card CVV tooltip hint image */
    securityCodeTooltip: {
      type: String,
      default: () => '',
    },
    /** Whether to render a checkbox for saving payment instrument */
    showSaveCardCheckbox: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { showSpinner, hideSpinner } = useLoader();
    const { initApplePaySession } = useApplePay(root, ApplePayContext.CHECKOUT);

    const isSelectedPaymentMethod = (method: string): boolean => {
      return (
        (!props.paymentCode || props.paymentCode === method) &&
        props.paymentMethod === method
      );
    };

    const showApplePayPaymentSheet = () => {
      showSpinner();
      initApplePaySession(props.applePayTranslations, hideSpinner, true);
    };

    return {
      isSelectedPaymentMethod,
      showApplePayPaymentSheet,
      PaymentMethodCode,
      ApplePayContext,
    };
  },
});
