//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, inject } from '@vue/composition-api';
import { focus } from '@vf/shared/src/utils/directives';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import ls from '@vf/composables/src/utils/localStorage';
export default defineComponent({
  name: 'VfVisualSearchBar',
  serverCacheKey: getCacheKeyFromProps,
  directives: { focus },
  inheritAttrs: false,
  props: {
    buttonText: {
      type: String,
      default: 'Search',
    },
    clearButtonText: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    requiredMessage: {
      type: String,
      default: '',
    },
    searchAllAsButton: {
      type: Boolean,
      default: false,
    },
    showClearButton: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String],
      default: null,
    },
    /** Tooltip labels */
    visualSearchLabel: {
      type: String,
      default: '',
    },
    visualSearchShowTooltip: {
      type: Boolean,
      default: true,
    },
    visualSearchTooltipCloseButtonText: {
      type: String,
      default: '',
    },
    visualSearchTooltipButtonText: {
      type: String,
      default: '',
    },
    visualSearchTooltipHtml: {
      type: String,
      default: '',
    },
    /** Display Syte button and tooltip, Requires GTM script for activations of Syte */
    displaySyte: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    return { isCoreRedesignEnabled };
  },
  data() {
    return {
      tooltipVisible: false,
      showTooltipTimer: null,
    };
  },
  mounted() {
    if (this.$root.$viewport.isSmall) {
      this.$refs.input.style.outline = 'none';
      this.$refs.input.focus();
    } else {
      // tooltip is desktop only
      if (!ls.getItem('clearedVisualSearchTip')) {
        this.tooltipVisible = true;
      }
    }
  },
  methods: {
    showTooltip() {
      if (this.visualSearchShowTooltip) {
        if (!this.$root.$viewport.isSmall) {
          //tooltip is desktop only
          this.showTooltipTimer = setTimeout(() => {
            this.tooltipVisible = true;
          }, 750);
        }
      }
    },
    cancelTooltipTimer() {
      clearTimeout(this.showTooltipTimer);
    },
    dismissTooltip(startSearch) {
      if (this.visualSearchShowTooltip) {
        this.tooltipVisible = false;
        if (!ls.getItem('clearedVisualSearchTip')) {
          ls.setItem('clearedVisualSearchTip', true);
        }
        if (startSearch) {
          document.querySelector('.vf-search-bar__visual-search--btn').click();
        }
      }
    },
  },
});
