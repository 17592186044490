






























































import {
  computed,
  defineComponent,
  PropType,
  inject,
} from '@vue/composition-api';
import { SearchSuggestionsResultsTranslations } from '../types';
import { SearchProduct } from '@vf/api-client/src/api-types';

export default defineComponent({
  props: {
    products: {
      type: Array as PropType<SearchProduct[]>,
      default: () => [],
    },
    getProductUrl: {
      type: Function,
      default: () => '',
    },
    resultsToShow: {
      type: Number,
      required: true,
      default: 3,
    },
    translations: {
      type: Object as PropType<SearchSuggestionsResultsTranslations>,
      default: () => ({
        featured: '$Featured Results$',
        seeMore: '$See More$',
      }),
    },
    handleSuggestedProductClicked: {
      type: Function,
      default: () => '',
    },
    search: {
      type: String,
      default: '',
    },
    handleSeeMoreClick: {
      type: Function,
      default: () => '',
    },
    searchSuggestionsThemeConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { root }) {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    const imageSize = computed(() => {
      const size = root.$viewport.size || 'medium';
      return {
        width: props.searchSuggestionsThemeConfig.imageSizes[size]?.width || 90,
        height:
          props.searchSuggestionsThemeConfig.imageSizes[size]?.height || 90,
      };
    });

    const mappedProducts = computed(() => {
      return props.products.slice(0, props.resultsToShow).map((product) => {
        return {
          pdpUrl: product.url,
          productImageURL: product.images,
          name: product.name,
          unitPrice: root.$formatPrice(
            product.price.amount,
            product.price.currency
          ),
        };
      });
    });

    const formattedAriaStatus = computed(() =>
      props.translations.resultsFoundAria
        .replace('{{numResults}}', mappedProducts.value.length.toString())
        .replace('{{searchTerm}}', props.search)
    );

    return {
      isCoreRedesignEnabled,
      imageSize,
      mappedProducts,
      formattedAriaStatus,
    };
  },
});
