


















import {
  defineComponent,
  inject,
} from '@vue/composition-api/dist/vue-composition-api';

export default defineComponent({
  name: 'VfFilterImage',
  props: {
    text: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const onClick = () => {
      if (props.active) {
        emit('click-remove');
      } else {
        emit('click-select');
      }
    };

    const isVansPlpRedesignEnabled = inject('isVansPlpRedesignEnabled');
    return {
      onClick,
      isVansPlpRedesignEnabled,
    };
  },
});
