//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { VueMaskDirective } from 'v-mask';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import {
  getCacheKeyFromProps,
  scrollToFirstError,
  checkPassword,
  checkName,
  checkAge,
  checkDate,
  stripPhone,
  stripPhoneAndPrependUSCAAreaCode,
} from '@vf/shared/src/utils/helpers';
import {
  getDateMaskFor,
  translateToApiDateFormat,
} from '@vf/shared/src/utils/form-helpers';
import { email, checkPhone } from '@vf/shared/src/utils/helpers/validators';
import { defineComponent } from '@vue/composition-api';
import useRootInstance from '../../theme/shared/useRootInstance';

export default defineComponent({
  name: 'VfCreateAccountBirthdate',
  directives: { mask: VueMaskDirective },
  mixins: [validationMixin],
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object,
      required: true,
    },
    prefillData: {
      type: Object,
      default: () => ({}),
    },
    subscribeTextCheckbox: {
      type: String,
      default:
        'Please send me emails with offers and updates. I understand that I can unsubscribe at any time.',
    },
    isLegacyUser: {
      type: Boolean,
      default: false,
    },
    minRegisterAge: {
      type: Number,
      required: true,
    },
    showPasswordToggler: {
      type: Boolean,
      default: false,
    },
    showPasswordHelper: {
      type: Boolean,
      default: true,
    },
    passwordValidationSteps: {
      type: Object,
      default: null,
    },
    headingLevel: {
      type: Number,
      default: () => 4,
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    showHeading: {
      type: Boolean,
      default: true,
    },
    showHeadingIcon: {
      type: Boolean,
      default: false,
    },
    showXplrPass: {
      type: Boolean,
      default: true,
    },
    /** Show sms subscription checkbox below phone number **/
    showSmsSubscription: Boolean,
    headingIcon: {
      type: String,
      default: '',
    },
    headingIconSize: {
      type: String,
      default: '',
    },
    uniqueEmailError: {
      type: Boolean,
      default: false,
    },
    phoneInputCountries: {
      type: Array,
      default: () => [],
    },
    phoneInputDefaultCountryCode: {
      type: String,
      default: 'US',
    },
    showPhoneInputCountry: {
      type: Boolean,
      default: false,
    },
    validations: {
      type: Object,
      default: () => ({
        birthdayRegex: '',
        zipCodeRegex: '',
        zipCodeFormat: '',
        phoneNumberFormat: '',
        phoneNumberMask: '',
      }),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    return {
      theme: root.$themeConfig.createAccountBirthdate,
      lockedIsLegacyUser: props.isLegacyUser,
      passwordValidators: {
        length: props.translations.passwordMinCharsLabel,
        number: props.translations.passwordNumberLabel,
        uppercase: props.translations.passwordUpperLetterLabel,
        lowercase: props.translations.passwordLowerLetterLabel,
      },
    };
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      zip: null,
      birthday: null,
      phone: null,
      password: null,
      isSubscribed: false,
      isTermsConfirmed: false, // Loyalty agreement and Sitewide T&C
      isPrivacyConfirmed: false, // Privacy Policy
      zipError: this.translations.validationMessages.zipError,
      isSmsSubscriptionConfirmed: false,
      isSmsSubscriptionTermsExpanded: false,
      lastValidPhoneNumber: null,
    };
  },
  computed: {
    isCreateButtonDisabled() {
      return this.buttonDisabled || this.$v.$invalid;
    },
    dateMask() {
      return getDateMaskFor('en-US');
    },
    buttonText() {
      return this.lockedIsLegacyUser
        ? this.translations.joinNowButtonText
        : this.translations.buttonText;
    },
    minRegisterAgeError() {
      return this.translations.validationMessages.userTooYoungError.replace(
        '{{minRegisterAge}}',
        this.minRegisterAge
      );
    },
    zipCodeFormatError() {
      return this.translations.validationMessages.zipError.replace(
        '{{zipCodeFormat}}',
        this.validations.zipCodeFormat
      );
    },
    birthdayRegex() {
      return this.validations?.birthdayRegex || `[\\d*]`;
    },
    birthdayErrorMessage() {
      const field = this.$v.birthday;
      if (!field.valid) {
        return this.translations.validationMessages.birthdayError;
      }
      if (!field.minAge) {
        return this.minRegisterAgeError;
      }
      return this.theme.birthdateRequired
        ? this.translations.validationMessages.requiredError.replace(
            '{{fieldName}}',
            this.translations.birthdayLabel.toLowerCase()
          )
        : null;
    },
    emailErrorMessage() {
      if (this.uniqueEmailError) {
        return this.translations.validationMessages.uniqueEmailError;
      }

      return !this.$v.email.required
        ? this.translations.validationMessages.requiredError.replace(
            '{{fieldName}}',
            this.translations.emailLabel.toLowerCase()
          )
        : this.translations.validationMessages.emailError;
    },
    phoneMask() {
      return this.validations.phoneNumberMask;
    },
  },
  watch: {
    prefillData: {
      immediate: true,
      handler(val) {
        if (val.firstName) {
          this.firstName = val.firstName;
          this.$v.firstName.$touch();
        }
        if (val.lastName) {
          this.lastName = val.lastName;
          this.$v.lastName.$touch();
        }
        if (val.email) {
          this.email = val.email;
          this.$v.email.$touch();
        }
        if (val.zip) {
          this.zip = val.zip;
          this.$v.zip.$touch();
        }
        if (val.phone) {
          this.phone = stripPhone(val.phone);
          this.$v.phone.$touch();
        }
        this.isSubscribed = val.isSubscribed;
      },
    },
  },
  methods: {
    async createAccount() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        await this.$nextTick();
        scrollToFirstError();
        return;
      }

      this.$emit('create-account', {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        postalCode: this.zip,
        birthDate: translateToApiDateFormat(this.birthday),
        phone: this.phone ? stripPhoneAndPrependUSCAAreaCode(this.phone) : '',
        isSubscribed: this.isSubscribed,
        isTermsConfirmed: this.isTermsConfirmed, // Loyalty agreement and Sitewide T&C
        isPrivacyConfirmed: this.isPrivacyConfirmed, // Privacy Policy
        ...(this.showSmsSubscription && {
          smsNotificationConsent: this.isSmsSubscriptionConfirmed,
        }),
      });
    },
    checkZipCMSRegex(zipCode) {
      const reg =
        this.translations && this.validations?.zipCodeRegex !== ''
          ? new RegExp(this.validations.zipCodeRegex, 'i')
          : /^[0-9]{5}(?:-[0-9]{4})?$/;

      if (!reg.test(zipCode)) {
        this.zipError = this.zipCodeFormatError;
        return false;
      }
      return true;
    },
    checkPhoneNumber(phoneNumber) {
      if (!phoneNumber) return true;
      return checkPhone(phoneNumber, this.phoneInputDefaultCountryCode);
    },
    resetValidation() {
      this.$v.$reset();
    },
    onPhoneChange() {
      if (this.isSmsSubscriptionConfirmed) {
        this.isSmsSubscriptionConfirmed = false;
      }

      if (this.phone === this.lastValidPhoneNumber) {
        this.isSmsSubscriptionConfirmed = true;
      }
    },
    onAcceptSMSAgreement() {
      this.lastValidPhoneNumber = this.phone;
      this.isSmsSubscriptionConfirmed = true;
      this.isSmsSubscriptionTermsExpanded = false;
    },
  },
  validations() {
    return {
      firstName: {
        required,
        name: checkName(),
      },
      lastName: {
        required,
        name: checkName(),
      },
      email: {
        required,
        email,
      },
      zip: {
        required,
        checkZip: this.checkZipCMSRegex,
      },
      birthday: {
        required: requiredIf(function () {
          return this.theme.birthdateRequired;
        }),
        valid: checkDate(),
        minAge: checkAge(this.minRegisterAge),
      },
      phone: {
        required: requiredIf(function () {
          return this.theme.phoneRequired;
        }),
        checkPhone: this.checkPhoneNumber,
      },
      password: {
        required: requiredIf(function () {
          return !this.lockedIsLegacyUser;
        }),
        checkPassword: function (val) {
          return !this.lockedIsLegacyUser ? checkPassword(val) : true;
        },
      },
      isTermsConfirmed: {
        required,
        sameAs: (val) => val === true,
      },
      isPrivacyConfirmed: {
        required,
        sameAs: (val) => val === true,
      },
    };
  },
});
