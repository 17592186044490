var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-password-helper"},[_c('VfText',{staticClass:"vf-password-helper__title",attrs:{"id":"passwordRequirements"}},[_vm._v("\n    "+_vm._s(_vm.passwordHelperText)+"\n  ")]),_vm._v(" "),(_vm.areAllRulesMatched && _vm.password)?_c('span',{staticClass:"sr-only",attrs:{"role":"alert"}},[_vm._v("\n    "+_vm._s(_vm.$t('passwordHelper.passwordPassingText'))+"\n  ")]):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"vf-password-helper__rules",attrs:{"aria-labelledby":"passwordRequirements"}},_vm._l((_vm.validations),function(ref){
var isMatched = ref.isMatched;
var rule = ref.rule;
var text = ref.text;
return _c('li',{key:rule,staticClass:"vf-password-helper__rules-item",class:{
        danger: !isMatched && _vm.password,
        success: isMatched,
        'half-width': _vm.columns > 1,
      }},[_c('VfIcon',{staticClass:"vf-password-helper__icon",attrs:{"icon":isMatched ? _vm.ui.successIcon : _vm.ui.errorIcon}}),_vm._v(" "),_c('VfText',[_vm._v(_vm._s(text))]),_vm._v(" "),(!isMatched && _vm.password)?_c('span',{staticClass:"sr-only",attrs:{"role":"alert"}},[_vm._v("\n        "+_vm._s((_vm.passwordHelperText + " " + text))+"\n      ")]):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }