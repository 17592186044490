




































































































































import { defineComponent, PropType, inject } from '@vue/composition-api';
import { required, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

export default defineComponent({
  name: 'VfSignIn',
  mixins: [validationMixin],
  props: {
    headingLevel: {
      type: Number as PropType<number>,
      default: 4,
    },
    heading: {
      type: [String, Boolean] as PropType<string | boolean>,
      default: 'Sign In',
    },
    subheading: {
      type: [String, Boolean] as PropType<string | boolean>,
      default: '',
    },
    emailLabel: {
      type: String as PropType<string>,
      default: 'Email Address',
    },
    passwordLabel: {
      type: String as PropType<string>,
      default: 'Password',
    },
    emailErrorMessage: {
      type: String as PropType<string>,
      default: 'Please enter a valid email address.',
    },
    requiredErrorMessage: {
      type: String as PropType<string>,
      default: 'This field is required, please add your {{fieldName}}.',
    },
    signInButtonText: {
      type: String as PropType<string>,
      default: 'Sign In',
    },
    forgotPasswordButtonText: {
      type: String as PropType<string>,
      default: 'Forgot password?',
    },
    forgotPasswordLink: {
      type: String as PropType<string>,
      default: '/account/forgot-password',
    },
    createAccountButtonLabel: {
      type: String as PropType<string>,
      default: 'Not a member?',
    },
    createAccountButtonText: {
      type: String as PropType<string>,
      default: 'Join Now',
    },
    createAccountLink: {
      type: String as PropType<string>,
      default: '/account/create-account',
    },
    bottomHeading: {
      type: String as PropType<string>,
      default: 'Register or log in with',
    },
    facebookUrl: {
      type: String as PropType<string>,
      default:
        'https://images.vans.com/is/image/Vans/facebook?$SCALE-ORIGINAL$',
    },
    facebookAriaLabel: {
      type: String as PropType<string>,
      default: 'Log in with Facebook',
    },
    showCreateAccountLink: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    showSocialLinks: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    showPasswordToggler: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    buttonDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    showCaptcha: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    signInAttempts: {
      type: Number as PropType<number>,
      default: 0,
    },
    initialMail: {
      type: String,
      default: '',
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    return {
      isCoreRedesignEnabled,
    };
  },
  data() {
    return {
      email: this.initialMail,
      password: null,
    };
  },
  methods: {
    async signIn() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.$emit('sign-in', {
        username: this.email,
        password: this.password,
      });
    },
    onForgotPasswordClick() {
      if (!this.forgotPasswordLink) {
        this.$emit('reset-password', 'reset');
      }
    },
  },
  validations: {
    email: {
      required,
      email: (eml) => email(eml ? eml.toLowerCase() : eml), // vuelidate considers domain names with captial letters as invalid
    },
    password: {
      required,
    },
  },
});
