//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { focus } from '@vf/shared/src/utils/directives';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'SearchBar',
  directives: { focus },
  serverCacheKey: getCacheKeyFromProps,
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: 'Search',
    },
    clearButtonText: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    requiredMessage: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: null,
    },
    showClearButton: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.$root.$viewport.isSmall) {
      this.$refs.input.style.outline = 'none';
      this.$refs.input.focus();
    }
  },
});
