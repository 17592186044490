












































































































































































































































































































import { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import { required, sameAs, requiredIf } from 'vuelidate/lib/validators';
import { VueMaskDirective } from 'v-mask';
import {
  getCacheKeyFromProps,
  ValidationSteps,
  checkPassword,
  checkDate,
  checkAge,
  checkName,
  stripPhoneAndPrependUSCAAreaCode,
} from '@vf/shared/src/utils/helpers';
import {
  getDateMaskFor,
  translateToApiDateFormat,
} from '@vf/shared/src/utils/form-helpers';
import { email, checkPhone } from '@vf/shared/src/utils/helpers/validators';
import { PhoneInputCountry } from '@vf/composables/src/useUtilities';
import { CountryCode } from 'libphonenumber-js';

interface PrefilledUserData {
  firstName?: string;
  lastName?: string;
  email?: string;
  isSubscribed?: boolean;
}

export default defineComponent({
  name: 'VfCreateAccount',
  directives: { mask: VueMaskDirective },
  mixins: [validationMixin],
  serverCacheKey: getCacheKeyFromProps,
  props: {
    minRegisterAge: {
      type: Number,
      default: 16,
    },
    prefillData: {
      type: Object as PropType<PrefilledUserData>,
      default: () => ({}),
    },
    headingLevel: {
      type: Number,
      default: () => 4,
    },
    heading: {
      type: String,
      default: () => 'Create Account',
    },
    subheading: {
      type: String,
      default: () => '',
    },
    firstNameLabel: {
      type: String,
      default: 'First Name',
    },
    lastNameLabel: {
      type: String,
      default: 'Last Name',
    },
    emailLabel: {
      type: String,
      default: 'Email Address',
    },
    requiredFieldsInfo: {
      type: String,
      default: '* All fields required.',
    },
    passwordLabel: {
      type: String,
      default: 'Password',
    },
    passwordHelperText: {
      type: String,
      default:
        'Password must contain at least 8 characters and include 1 digit and 1 letter and 1 uppercase letter and 1 lowercase letter.',
    },
    passwordValidationSteps: {
      type: Object as PropType<ValidationSteps>,
      default: () => ({
        length: 'at least 8 characters',
        uppercase: '1 uppercase letter',
        number: '1 number',
        lowercase: '1 lowercase letter',
      }),
    },
    confirmPasswordLabel: {
      type: String,
      default: 'Confirm Password',
    },
    zipCodeLabel: {
      type: String,
      default: '',
    },
    birthdayLabel: {
      type: String,
      default: '',
    },
    phoneNumberLabel: {
      type: String,
      default: '',
    },
    countryCodeLabel: {
      type: String,
      default: '',
    },
    showPassword: {
      type: Boolean,
      default: true,
    },
    showPasswordHelper: {
      type: Boolean,
      default: false,
    },
    showConfirmPassword: {
      type: Boolean,
      default: true,
    },
    showPhone: {
      type: Boolean,
      default: false,
    },
    showZipCode: {
      type: Boolean,
      default: false,
    },
    showBirthday: {
      type: Boolean,
      default: false,
    },
    showTwoColumns: {
      type: Boolean,
      default: false,
    },
    termsTextCheckbox: {
      type: String,
      default:
        'By selecting the checkbox, you declare that you have read and agree to the <a href="/terms-of-use" target="_blank">Terms of Use</a>.',
    },
    privacyPolicyText: {
      type: String,
      default:
        'By selecting the checkbox, you declare that you have read and agree to the <a href="/terms-of-use" target="_blank">Privacy Policy</a>.',
    },
    subscribeTextCheckbox: {
      type: String,
      default:
        'Please send me emails with offers and updates. I understand that I can unsubscribe at any time.',
    },
    buttonText: {
      type: String,
      default: 'Create Account',
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      default: 'en-US',
    },
    overridenData: Object,
    /** Validations for the form */
    validations: {
      type: Object,
      default: () => ({
        phoneNumberRegex: '',
        zipCodeRegex: '',
        birthdayRegex: '',
        phoneNumberFormat: '',
      }),
    },
    validationMessages: {
      type: Object,
      default: () => {
        return {
          requiredError:
            'This field is required, please add your {{fieldName}}.',
          nameError: 'Please enter a valid name',
          emailError: 'Please enter a valid email address.',
          passwordError: 'Please enter a valid password.',
          confirmPasswordError: 'Please enter the same value again.',
          birthdayError: 'Please enter a valid date',
          phoneError: 'A valid phone number is required',
          termsError:
            'You must accept the Terms of Use before you create an account.',
          privacyPolicyError:
            'You must accept the Privacy Policy before you create an account.',
        };
      },
    },
    phoneInputCountries: {
      type: Array as PropType<PhoneInputCountry[]>,
      default: () => [],
    },
    phoneInputDefaultCountryCode: {
      type: String as PropType<CountryCode>,
      default: 'US',
    },
    showPhoneInputCountry: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      firstName: this.overridenData ? this.overridenData.firstName : null,
      lastName: this.overridenData ? this.overridenData.lastName : null,
      email: this.overridenData ? this.overridenData.email : null,
      password: null,
      repeatPassword: null,
      isSubscribed: false,
      isTermsConfirmed: false,
      isPrivacyPolicyConfirmed: false,
      postalCode: this.overridenData ? this.overridenData.postalCode : null,
      birthday: null,
      phone: this.overridenData ? this.overridenData.phone : null,
      postalCodeError: this.validationMessages.postalCodeError,
    };
  },
  computed: {
    dateMask() {
      return getDateMaskFor('en-US');
    },
    phoneMask() {
      return this.validations.phoneNumberMask;
    },
    birthdayRegex() {
      return this.validations?.birthdayRegex || `[\\d*]`;
    },
    minRegisterAgeError() {
      return this.validationMessages.minRegisterAgeError?.replace(
        '{{minRegisterAge}}',
        this.minRegisterAge
      );
    },
    birthdayErrorMessage() {
      const field = this.$v.birthday;

      if (!field.required) {
        return this.getRequiredErrorMessage(this.birthdayLabel);
      }

      if (!field.valid) {
        return this.validationMessages.birthdayError;
      }

      if (!field.minAge) {
        return this.minRegisterAgeError;
      }

      return this.getRequiredErrorMessage(this.birthdayLabel);
    },
    countryCode() {
      return this.locale.split('-')[1];
    },
  },
  watch: {
    prefillData: {
      immediate: true,
      handler(val: PrefilledUserData) {
        if (val.firstName) this.firstName = val.firstName;
        if (val.lastName) this.lastName = val.lastName;
        if (val.email) this.email = val.email;
        this.isSubscribed = val.isSubscribed;
      },
    },
  },
  methods: {
    getRequiredErrorMessage(fieldName) {
      return this.validationMessages.requiredError.replace(
        '{{fieldName}}',
        fieldName.toLowerCase()
      );
    },
    createAccount() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$emit('create-account', {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        confirmPassword: this.confirmPassword || '',
        birthDate: translateToApiDateFormat(this.birthday),
        ...(this.postalCode && { postalCode: this.postalCode }),
        ...(this.phone && {
          phone: stripPhoneAndPrependUSCAAreaCode(this.phone),
        }),
        isTermsConfirmed: this.isTermsConfirmed,
        isPrivacyPolicyConfirmed: this.isPrivacyPolicyConfirmed,
        isSubscribed: this.isSubscribed,
      });
    },
    checkPostalCodeCMSRegex(zipCode) {
      if (!this.showZipCode) return true;
      const reg =
        this.validations && this.validations.zipCodeRegex !== ''
          ? new RegExp(this.validations.zipCodeRegex, 'i')
          : /^\d{3}[\s,]?\d{3}$/;

      if (!reg.test(zipCode)) {
        this.postalCodeError = this.validationMessages.postalCodeError;
        return false;
      }
      return true;
    },
    checkPhoneNumber(phoneNumber) {
      if (!phoneNumber || !this.showPhone) return true;
      return checkPhone(this.phone, this.countryCode);
    },
  },
  validations() {
    return {
      firstName: {
        required,
        name: checkName(),
      },
      lastName: {
        required,
        name: checkName(),
      },
      email: {
        required,
        email,
      },
      isTermsConfirmed: {
        required,
        sameAs: (val) => val === true,
      },
      isPrivacyPolicyConfirmed: {
        required,
        sameAs: (val) => val === true,
      },
      password: {
        required: requiredIf(function () {
          return this.showPassword;
        }),
        checkPassword: (value) => {
          return this.showPassword ? checkPassword(value) : true;
        },
      },
      repeatPassword: {
        required: requiredIf(function () {
          return this.showConfirmPassword;
        }),
        sameAsPassword: sameAs(function () {
          return this.showConfirmPassword ? this.password : null;
        }),
      },
      postalCode: {
        required: requiredIf(function () {
          return this.showZipCode;
        }),
        checkZipCode: this.checkPostalCodeCMSRegex,
      },
      birthday: {
        required: requiredIf(function () {
          return this.showBirthday;
        }),
        valid: (value) => (this.showBirthday ? checkDate()(value) : true),
        minAge: (value) =>
          this.showBirthday ? checkAge(this.minRegisterAge)(value) : true,
      },
      phone: {
        required: requiredIf(function () {
          return this.showPhone;
        }),
        checkPhone: this.checkPhoneNumber,
      },
    };
  },
});
