var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mappedProducts.length)?_c('div',{staticClass:"search-suggestions__results"},[_c('div',{staticClass:"sr-only",attrs:{"role":"status","aria-live":"polite"}},[_vm._v("\n    "+_vm._s(_vm.formattedAriaStatus)+"\n  ")]),_vm._v(" "),_c('h2',{staticClass:"search-suggestions__results-heading",class:{
      'search-suggestions__results-heading--redesign-core': _vm.isCoreRedesignEnabled,
    }},[_c('VfText',{staticClass:"search-suggestions__results-heading-featured",attrs:{"font-family":"secondary","content":_vm.translations.featured}}),_vm._v(" "),(_vm.searchSuggestionsThemeConfig.showSeeMoreButtonInResults)?_c('VfButton',{staticClass:"vf-button--text search-suggestions__see-more--desktop large-only",nativeOn:{"click":function($event){return _vm.handleSeeMoreClick(_vm.search)}}},[_vm._v("\n      "+_vm._s(_vm.translations.seeMore)+"\n    ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"search-suggestions__results-list",class:{
      'search-suggestions__results-list--device':
        _vm.searchSuggestionsThemeConfig.useDeviceBreakpoints,
    },style:({
      '--image-width': ((_vm.imageSize.width) + "px"),
      '--vf-search-suggestions-list-max-two-row-height':
        3 * _vm.imageSize.height + 'px',
    })},_vm._l((_vm.mappedProducts),function(product,key){return _c('ThemeProductLine',{key:key,staticClass:"search-suggestions__product",class:{
        'search-suggestions__product--device':
          _vm.searchSuggestionsThemeConfig.useDeviceBreakpoints,
      },attrs:{"product":product,"image-height":_vm.imageSize.height,"image-width":_vm.imageSize.width,"translations":_vm.translations,"role":"option","tabindex":"-1"},on:{"click:link":_vm.handleSuggestedProductClicked},scopedSlots:_vm._u([{key:"details1",fn:function(){return [_c('div',{staticClass:"search-suggestions__bottom-price"},[_c('VfPrice',{attrs:{"regular":product.unitPrice}})],1)]},proxy:true}],null,true)})}),1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }