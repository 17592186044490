//
//
//
//
//
//
//
//
//

import { scrollTo as scrollToTop } from '@vf/shared/src/utils/helpers';
import WriteReviewWidget from '@/components/WriteReviewWidget.vue';

export default {
  components: {
    WriteReviewWidget,
  },
  layout: 'defaultStatic',
  setup() {
    if (typeof window !== 'undefined') {
      scrollToTop();
    }
  },
};
