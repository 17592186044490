
































































































import { defineComponent, inject, PropType } from '@vue/composition-api';
import { ChangePasswordValidationMessages } from '@vf/api-contract';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { checkPassword, ValidationSteps } from '@vf/shared/src/utils/helpers';
import useRootInstance from '../../theme/shared/useRootInstance';

export default defineComponent({
  name: 'VfChangePasswordB',
  mixins: [validationMixin],
  props: {
    heading: {
      type: String as PropType<string>,
      default: 'Change Password',
    },
    subheading: {
      type: String as PropType<string>,
      default:
        'Password must contain at least 8 characters and include 1 digit and 1 letter and 1 uppercase letter and 1 lowercase letter.',
    },
    passwordLabel: {
      type: String as PropType<string>,
      default: 'New Password',
    },
    oldPasswordLabel: {
      type: String as PropType<string>,
      default: 'Old Password',
    },
    confirmPasswordLabel: {
      type: String as PropType<string>,
      default: 'Confirm New Password',
    },
    showText: {
      type: String as PropType<string>,
      default: 'Show',
    },
    hideText: {
      type: String as PropType<string>,
      default: 'Hide',
    },
    validationMessages: {
      type: Object as PropType<ChangePasswordValidationMessages>,
      required: true,
    },
    passwordHelperText: {
      type: String,
      default: 'Password Must contain',
    },
    validationSteps: {
      type: Object as PropType<ValidationSteps>,
      required: true,
    },
    resetPasswordButton: {
      type: String as PropType<string>,
      default: 'Reset Password',
    },
    showOldPasswordInput: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disableSubmit: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    return {
      ui: {
        submitButtonClassVariantB:
          'vf-button--medium' +
          (isCoreRedesignEnabled ? '' : 'vf-button--secondary'),
        subtitleModifierVariantB: 'font-base',
        ...(root.$themeConfig?.changePassword || {}),
      },
    };
  },
  data() {
    return {
      password: null,
      oldPassword: null,
    };
  },
  methods: {
    changePassword() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$emit('change-password', {
        previousPassword: this.oldPassword,
        password: this.password,
      });
      this.resetData();
    },
    cancelPasswordChange() {
      this.resetData();
    },
    resetData() {
      this.oldPassword = null;
      this.password = null;
      // To avoid displaying error after reseting data.
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
  validations: {
    password: {
      required,
      checkPassword,
    },
    oldPassword: {
      required,
    },
  },
});
