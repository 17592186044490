






































































































import { defineComponent, PropType } from '@vue/composition-api';
import { ChangePasswordValidationMessages } from '@vf/api-contract';
import { validationMixin } from 'vuelidate';
import { required, requiredIf, sameAs } from 'vuelidate/lib/validators';
import { checkPassword } from '@vf/shared/src/utils/helpers';
import useRootInstance from '../../theme/shared/useRootInstance';

export default defineComponent({
  name: 'VfChangePasswordA',
  mixins: [validationMixin],
  props: {
    heading: {
      type: String as PropType<string>,
      default: 'Change Password',
    },
    subheading: {
      type: String as PropType<string>,
      default:
        'Password must contain at least 8 characters and include 1 digit and 1 letter and 1 uppercase letter and 1 lowercase letter.',
    },
    passwordLabel: {
      type: String as PropType<string>,
      default: 'New Password',
    },
    oldPasswordLabel: {
      type: String as PropType<string>,
      default: 'Old Password',
    },
    confirmPasswordLabel: {
      type: String as PropType<string>,
      default: 'Confirm New Password',
    },
    validationMessages: {
      type: Object as PropType<ChangePasswordValidationMessages>,
      required: true,
    },
    changePasswordButton: {
      type: String as PropType<string>,
      default: 'Change Password',
    },
    cancelButton: {
      type: String as PropType<string>,
      default: 'Cancel',
    },
    cancelButtonLink: {
      type: String as PropType<string>,
      default: '#',
    },
    showCancelButton: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    showOldPasswordInput: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    disableSubmit: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup() {
    const { root } = useRootInstance();
    return {
      ui: {
        submitButtonClassVariantA: 'vf-button--medium',
        subtitleModifierVariantA: 'font-base',
        cancelButtonClass: 'vf-button--secondary',
        headingTitleModifier: 'title-4',
        ...(root.$themeConfig?.changePassword || {}),
      },
    };
  },
  data() {
    return {
      oldPassword: null,
      password: null,
      repeatPassword: null,
    };
  },
  mounted() {
    this.$root.$on('resetPasswordData', this.resetData);
  },
  beforeDestroy() {
    this.$root.$off('resetPasswordData', this.resetData);
  },
  methods: {
    changePassword() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$emit('change-password', {
        previousPassword: this.oldPassword,
        password: this.password,
        repeatPassword: this.repeatPassword,
      });
      this.resetData();
    },
    resetData() {
      this.oldPassword = null;
      this.password = null;
      this.repeatPassword = null;
      // To avoid displaying error after reseting data.
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
  validations: {
    oldPassword: {
      required: requiredIf(function () {
        return this.showOldPasswordInput;
      }),
    },
    password: {
      required,
      checkPassword: function (v) {
        return checkPassword(v);
      },
    },
    repeatPassword: {
      required,
      sameAsPassword: sameAs('password'),
    },
  },
});
