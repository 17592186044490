//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VfAddressSummaryRow from './Atom.AddressSummaryRow.vue';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfAddressSummary',
  components: { VfAddressSummaryRow },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    hasAddress: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: 'Address Book',
    },
    text: {
      type: String,
      default: 'Default Shipping Address:',
    },
    textNoAddress: {
      type: String,
      default: 'You have no saved address',
    },
    ctaText: {
      type: String,
      default: 'View All Addresses',
    },
    ctaLink: {
      type: String,
      default: '',
    },
    ctaTextNoAddress: {
      type: String,
      default: 'Add An Address',
    },
    nameLabel: {
      type: String,
      default: 'Name:',
    },
    nameText: {
      type: String,
      default: '',
    },
    addressLabel: {
      type: String,
      default: 'Address:',
    },
    addressText: {
      type: String,
      default: '',
    },
    phoneLabel: {
      type: String,
      default: 'Phone:',
    },
    phoneText: {
      type: String,
      default: '',
    },
  },
});
