import { render, staticRenderFns } from "./Organism.ChangePassword.VARIANT_B.vue?vue&type=template&id=1cdddd16&scoped=true&"
import script from "./Organism.ChangePassword.VARIANT_B.vue?vue&type=script&lang=ts&"
export * from "./Organism.ChangePassword.VARIANT_B.vue?vue&type=script&lang=ts&"
import style0 from "./Organism.ChangePassword.VARIANT_B.vue?vue&type=style&index=0&id=1cdddd16&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cdddd16",
  null
  
)

export default component.exports