//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { focus } from '@vf/shared/src/utils/directives';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfSocialMediaLink',
  directives: { focus },
  serverCacheKey: getCacheKeyFromProps,
  props: {
    heading: {
      type: String,
      default: '',
    },
    subheading: {
      type: String,
      default: '',
    },
    headingLevel: {
      type: [Number, String],
      default: 5,
    },
    ariaLabel: {
      type: [String, Array],
      default: '',
    },
    imageWidth: {
      type: [String, Number],
      default: null,
    },
    imageHeight: {
      type: [String, Number],
      default: null,
    },
    socialLink: {
      type: [String, Array],
      default:
        'https://images.vans.com/is/image/Vans/facebook?$SCALE-ORIGINAL$',
    },
  },
});
