const englishTranslations = {
  earliestAvailability: 'Sort by Earliest Availability',
  getDirections: 'Get Directions',
  howToGetIt: 'How to Get it at the Store',
  locationNotRecognized: 'Location is not recognized. Please try again.',
  lessStores:
    'Showing stores within {distance} miles. Update your location to find more options.',
  noStores:
    'No stores within {distance} miles have your product available. Update your location to find more options.',
  onlyLeft: 'Only {quantity} left!',
  saturday: 'Saturday',
  search: 'Search',
  showDetails: 'Show Details',
  storeHours: 'Store Hours',
  storeSelected: '{name} is selected.',
  storeUpdated: 'Store has been updated.',
  sunday: 'Sunday',
  valueRequired: 'This field is required.',
  weekdays: 'Weekdays',
  zipCode: 'City & State, or Zip Code',
};

export const shippingDestinationsStoreSearch = {
  'en-us': englishTranslations,
  'en-ca': englishTranslations,
  'fr-ca': {
    ...englishTranslations,
    valueRequired: 'Ce champ est obligatoire.',
  },
};
