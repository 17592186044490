const englishTranslations = {
  billingAddress: 'Billing Address',
  sameAsShipping: 'Same as shipping address',
  addDifferent: 'Add different billing address',
};

export const billingAddressSelector = {
  'en-us': englishTranslations,
  'en-ca': englishTranslations,
  'fr-ca': {
    billingAddress: 'Adresse de facturation',
    sameAsShipping: 'Identique à l’adresse de livraison',
    addDifferent: 'Ajouter une autre adresse de facturation',
  },
};
