//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfAddressSummaryRow',
  props: {
    /** Definition Title */
    title: {
      type: String,
      default: '',
    },
    /** Definition Description */
    description: {
      type: String,
      default: '',
    },
  },
});
