








































import { defineComponent, PropType, inject } from '@vue/composition-api';
import { focus } from '@vf/shared/src/utils/directives';
import { SearchSuggestionsListTranslations } from '../types';

export default defineComponent({
  directives: { focus },
  props: {
    suggestions: {
      type: Array as PropType<{ label: string }[]>,
      default: () => [],
    },
    getSearchLink: {
      type: Function,
      default: () => '',
    },
    suggestionsToShow: {
      type: Number,
      required: true,
      default: 7,
    },
    handleSearchSuggestionClicked: {
      type: Function,
      default: () => '',
    },
    translations: {
      type: Object as PropType<SearchSuggestionsListTranslations>,
      default: () => ({
        topSearches: '$Top Searches$',
      }),
    },
    searchQuery: {
      type: String as PropType<string>,
      default: '',
    },
    searchSuggestionsThemeConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const {
      markQueryInResultLabels,
      showTopSearchesHeading,
    } = props.searchSuggestionsThemeConfig;
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    const getMarkedLabel = (label: string) => {
      if (!props.searchQuery) {
        return label;
      }
      const pattern = new RegExp(`(${props.searchQuery})`, 'igm');
      return label.replace(pattern, '<strong>$1</strong>');
    };

    return {
      markQueryInResultLabels,
      getMarkedLabel,
      showTopSearchesHeading,
      isCoreRedesignEnabled,
    };
  },
});
