//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SearchSuggestionsList from './Organism.SearchSuggestionsList.vue';
import SearchSuggestionsResults from './Organism.SearchSuggestionsResults.vue';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { SearchType } from '@vf/composables/src/useSearch/types';
import { computed, defineComponent } from '@vue/composition-api';
import { focusTrap } from '@vf/shared/src/utils/directives';

export default defineComponent({
  name: 'SearchSuggestions',
  serverCacheKey: getCacheKeyFromProps,
  components: {
    SearchSuggestionsList,
    SearchSuggestionsResults,
  },
  directives: {
    focusTrap,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    suggestionsToShow: {
      type: Number,
      required: true,
      default: 7,
    },
    resultsToShow: {
      type: Number,
      required: true,
      default: 3,
    },
    searchAll: {
      type: String,
      default: 'Search all',
    },
    seeMore: {
      type: String,
      default: 'See more',
    },
    resultsFoundAria: {
      type: String,
    },
    featured: {
      type: String,
      default: 'Featured Results',
    },
    closeSuggestions: {
      type: String,
      default: '',
    },
    topSearches: {
      type: String,
      default: '$Top Searches$',
    },
    getProductUrl: {
      type: Function,
      default: () => '',
    },
    getSearchLink: {
      type: Function,
      default: () => '',
    },
    searchSuggestionsThemeConfig: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const products = computed(
      () =>
        props.data?.products?.filter((product) => !!product.price.amount) || []
    );
    const suggestions = computed(() => props.data.terms || []);
    const searchButtonClassName = computed(
      () =>
        props.searchSuggestionsThemeConfig.buttonClass || 'vf-button--tertiary'
    );

    const showSearchSuggestionsPhrase =
      props.searchSuggestionsThemeConfig.showSearchSuggestionsPhrase;
    const showSearchSuggestionsCloseButton =
      props.searchSuggestionsThemeConfig.showSearchSuggestionsCloseButton;

    const resultTranslations = computed(() => ({
      seeMore: props.seeMore,
      resultsFoundAria: props.resultsFoundAria,
      featured: props.featured,
    }));

    const listTranslations = computed(() => ({
      topSearches: props.topSearches,
    }));

    const handleSearchSuggestionClicked = (link, searchTermAdj) => {
      emit('search', searchTermAdj);
      emit('set-pdo-search', {
        searchTermAdj: searchTermAdj,
        searchType: SearchType.CLICK,
      });
      emit('clicked', link);
      emit('close');
    };

    const handleSuggestedProductClicked = () => {
      emit('set-pdo-search', {
        searchResults: 1,
        searchType: SearchType.CLICK,
      });
      emit('close');
    };

    const handleSeeMoreClick = (search) => emit('search', search);

    return {
      products,
      suggestions,
      showSearchSuggestionsPhrase,
      showSearchSuggestionsCloseButton,
      searchButtonClassName,
      resultTranslations,
      listTranslations,
      handleSearchSuggestionClicked,
      handleSuggestedProductClicked,
      handleSeeMoreClick,
    };
  },
});
