const englishTranslations = {
  employee: {
    exceededMessage:
      'Your account has reached the annual VF associate discount limit and is no longer eligible for employee discount at this time. If you have questions, contact the VF People Services Center via the <a href="https://vfc.service-now.com/hr/" target="_blank">My HR Service Center portal</a> or contact the VF People Service Center at 800-576-9501 or <a href="mailto:myhr@vfc.com">myhr@vfc.com</a>.',
    message:
      'VF Associate Discount: You have spent {{amount}} out of your annual associate discount limit of {{limit}} on {{brand}}.',
  },
  athlete: {
    exceededMessage:
      'Your account has reached your annual Athlete Credit limit and is no longer eligible for Athlete Credit at this time.',
    message:
      'Athlete Credit: You have spent {{amount}} out of your annual Athlete Credit limit of {{limit}} on {{brand}}.',
  },
  ipa: {
    exceededMessage:
      'IPA: Your account has reached the IPA discount limit and is no longer eligible for discount at this time. If you have questions, contact the <a href="mailto:TNFPro_Support@vfc.com">The North Face Customer Service Team</a> at <a href="mailto:TNFPro_Support@vfc.com">TNFPro_Support@vfc.com</a>.',
    message:
      'IPA Discount: You have spent {{amount}} out of your discount limit of {{limit}} on {{brand}}.',
  },
  wranx: {
    exceededMessage:
      'Wranx: Your account has reached the Wranx discount limit and is no longer eligible for discount at this time. If you have questions, contact the <a href="mailto:TNFPro_Support@vfc.com">The North Face Customer Service Team</a> at <a href="mailto:TNFPro_Support@vfc.com">TNFPro_Support@vfc.com</a>.',
    message:
      'Wranx Discount: You have spent {{amount}} out of your discount limit of {{limit}} on {{brand}}.',
  },
};

export const discountNotification = {
  'en-us': englishTranslations,
  'en-ca': englishTranslations,
  'fr-ca': {
    employee: {
      exceededMessage:
        'Votre compte a atteint la limite de remise annuelle des associés VF et n\'est plus éligible à la remise des employés pour le moment. Si vous avez des questions, contactez le VF People Services Center via le portail <a href="https://vfc.service-now.com/hr/" target="_blank">My HR Service Center</a> ou contactez le VF People Service Center au 800-576-9501 ou <a href="mailto:myhr@vfc.com">myhr@vfc.com</a>.',
      message:
        "Vous avez dépensé {{amount}} sur votre limite annuelle de {{limit}} d'escompte employé sur {{brand}}.",
    },
    athlete: {
      exceededMessage:
        'Votre compte a atteint la limite annuelle de crédit athlète et vous ne pouvez plus accéder à des crédit athlète en ce moment.',
      message:
        'Crédits athlète : Vous avez dépensé {{amount}} sur votre limite annuelle de crédit athlète de {{limit}} sur {{brand}}.',
    },
    ipa: {
      exceededMessage:
        'IPA: Votre compte a atteint la limite de rabais IPA et vous ne pouvez pas accéder à des rabais en ce moment. Si vous avez des questions, veuillez contacter l\'<a href="mailto:TNFPro_Support@vfc.com">équipe du service à la clientèle The North Face</a> à <a href="mailto:TNFPro_Support@vfc.com">TNFPro_Support@vfc.com</a>.',
      message:
        'Rabais IPA: Vous avez dépensé {{amount}} sur votre limite de remise de {{limit}} sur {{brand}}.',
    },
    wranx: {
      exceededMessage:
        'Wranx: Votre compte a atteint la limite de rabais Wranx et vous ne pouvez pas accéder à des rabais en ce moment. Si vous avez des questions, veuillez contacter l’<a href="mailto:TNFPro_Support@vfc.com">équipe du service à la clientèle The North Face/a> à <a href="mailto:TNFPro_Support@vfc.com">TNFPro_Support@vfc.com</a>.',
      message:
        'Rabais Wranx: Vous avez dépensé {{amount}} sur votre limite de remise de {{limit}} sur {{brand}}.',
    },
  },
};
