







import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  layout: 'cms/cmsDefault',
  asyncData({ res, route }) {
    const { errorCode } = route.params;
    const useCmsContent = errorCode.startsWith('4');

    res.statusCode = parseInt(route.params.errorCode);

    return {
      errorCode,
      useCmsContent,
    };
  },
});
