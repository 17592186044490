var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"vf-change-password",attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();}}},[_c('VfHeading',{staticClass:"vf-change-password__heading",attrs:{"level":4,"title":_vm.heading,"title-modifier":"title-4","subtitle":_vm.subheading,"subtitle-modifier":_vm.ui.subtitleModifierVariantB}}),_vm._v(" "),_c('VfRow',[_c('VfColumn',{staticClass:"vf-change-password__old-password-col",attrs:{"small":"12","medium":"6","large":"6"}},[_c('VfInput',{staticClass:"vf-input--white-background vf-change-password__input",attrs:{"data-testid":"change-password__old-password__input","type":"password","aria-label":_vm.oldPasswordLabel,"label":_vm.oldPasswordLabel,"name":("" + _vm.oldPasswordLabel + _vm.heading),"valid":!_vm.$v.oldPassword.$error,"error-message":!_vm.$v.oldPassword.required
            ? _vm.validationMessages.required.replace(
                '{{fieldName}}',
                _vm.oldPasswordLabel.toLowerCase()
              )
            : _vm.validationMessages.oldPassword,"autocomplete":"off"},on:{"blur":function($event){return _vm.$v.oldPassword.$touch()}},model:{value:(_vm.$v.oldPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.oldPassword, "$model", $$v)},expression:"$v.oldPassword.$model"}})],1),_vm._v(" "),_c('VfColumn',{staticClass:"vf-change-password__password-col",attrs:{"small":"12","medium":"6","large":"6"}},[_c('VfInput',{staticClass:"vf-input--white-background vf-change-password__input",attrs:{"data-testid":"change-password__password__input","type":"password","aria-label":_vm.passwordLabel,"label":_vm.passwordLabel,"name":("" + _vm.passwordLabel + _vm.heading),"valid":!_vm.$v.password.$error,"required":"","error-message":!_vm.$v.password.required
            ? _vm.validationMessages.required.replace(
                '{{fieldName}}',
                _vm.passwordLabel.toLowerCase()
              )
            : _vm.validationMessages.password,"autocomplete":"off","has-show-password":"","show-password-text":_vm.showText,"hide-password-text":_vm.hideText},on:{"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", $$v)},expression:"$v.password.$model"}}),_vm._v(" "),_c('VfPasswordHelper',{staticClass:"vf-change-password__password-helper",attrs:{"password":_vm.password,"password-helper-text":_vm.passwordHelperText,"validation-steps":_vm.validationSteps}})],1)],1),_vm._v(" "),_c('div',{staticClass:"vf-change-password__action-buttons"},[_vm._t("confirm-button",function(){return [_c('VfButton',{class:_vm.ui.submitButtonClassVariantB,attrs:{"data-testid":"change-password__submit__btn","role":"button","role-description":_vm.resetPasswordButton,"disabled":_vm.disableSubmit},on:{"click":_vm.changePassword}},[_vm._v("\n        "+_vm._s(_vm.resetPasswordButton)+"\n      ")])]},null,{ resetPasswordButton: _vm.resetPasswordButton, changePassword: _vm.changePassword })],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }