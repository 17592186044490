//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfTab',
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    selected: {
      type: String,
      default: '',
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const isChecked = computed(() => props.value === props.selected);
    const buttonClass = computed(() =>
      isChecked.value ? 'vf-radio-is-active' : ''
    );

    return {
      buttonClass,
      isChecked,
    };
  },
});
