//
//
//
//
//
//
//
//
//

export default {
  layout: 'cms/cmsDefault',
};
