
















import {
  computed,
  defineComponent,
  onBeforeMount,
  onUnmounted,
  watch,
} from '@vue/composition-api';
import { PaymentMethodCode } from '@vf/api-client';
import {
  useAccount,
  useApplePay,
  useCart,
  useCheckout,
  useKlarna,
  usePayPal,
  useValidation,
} from '@vf/composables';
import { isPayPalFlow } from '@/helpers';
import useRootInstance from '@/shared/useRootInstance';

import { useCard } from './composable/useCard';
import { useCheckoutPaymentGTM } from './composable/useCheckoutPaymentGTM';
import { CheckoutPaymentInfoProps } from './CheckoutPaymentInfoProps';

export default defineComponent({
  name: 'CheckoutPaymentInfo',
  components: {
    CheckoutPaymentInfoDefault: () =>
      import(
        '@/components/smart/checkout/CheckoutPaymentInfo/variants/CheckoutPaymentInfoDefault.vue'
      ),
    CheckoutPaymentInfoSeparately: () =>
      import(
        '@/components/smart/checkout/CheckoutPaymentInfo/variants/CheckoutPaymentInfoSeparately.vue'
      ),
  },
  props: CheckoutPaymentInfoProps,
  setup() {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.checkoutPaymentInfo;

    const {
      paymentFormAccordion,
      paymentMethodsData,
      paymentMethod,
      getPaymentMethods,
      handleAmountToPayUpdate,
      savedCreditCard,
      isZeroOrder,
      resetPlaceOrderButton,
    } = useCheckout(root);

    const { payFullWithGiftCard, cart, hasItems } = useCart(root);

    const { paymentInstruments, defaultPaymentInstrument } = useAccount(root);

    const {
      isKlarnaInPaymentMethods,
      isKlarnaSdkLoaded,
      loadKlarnaSdk,
      getKlarnaToken,
    } = useKlarna(root);

    const { isApplePayAvailable, unselectApplePayAsDefault } = useApplePay(
      root
    );
    const { payPalCheckoutFlowInProgress } = usePayPal(root);

    const { showAddNewCreditCardForm, setCreditCardPaymentMethod } = useCard({
      savedCreditCard,
      paymentInstruments,
      paymentMethod,
    });

    const { disableWatch: paymentMethodGtmUnwatch } = useCheckoutPaymentGTM({
      paymentMethodsData,
      paymentMethod,
    });

    const isVisible = computed(
      () => hasItems.value && !isZeroOrder.value && !payFullWithGiftCard.value
    );

    const renderVariant = computed(() =>
      theme.showMethodVariantsSeparately
        ? 'CheckoutPaymentInfoSeparately'
        : 'CheckoutPaymentInfoDefault'
    );

    const handlePaymentMethodChange = (value) => {
      const { $v } = useValidation(root, 'GIFT_CARD_FORM');

      if ($v.value && !$v.value.cardNumber.$model && !$v.value.pin.$model) {
        $v.value.$reset();
      }

      if (value) {
        resetPlaceOrderButton();
      }

      if (value === PaymentMethodCode.KLARNA) {
        getKlarnaToken();
      }

      if (value !== PaymentMethodCode.CREDIT_CARD) {
        if (!value && theme.skipHandlingNonCreditCardChange) return;
        savedCreditCard.value = '';
        showAddNewCreditCardForm.value = false;
      }
    };

    const handleKlarnaBeforeMount = async () => {
      const klarnaConditions = [
        isKlarnaInPaymentMethods(paymentMethodsData),
        !isKlarnaSdkLoaded.value,
      ];
      if (klarnaConditions.every(Boolean)) {
        await loadKlarnaSdk();
        if (paymentMethod.value === PaymentMethodCode.KLARNA) {
          getKlarnaToken();
        }
      }
    };

    onBeforeMount(async () => {
      watch(paymentMethod, (val) => handlePaymentMethodChange(val), {
        immediate: true,
      });
      /** Fetch payment methods from API */
      await getPaymentMethods();

      /** PayPal */
      if (
        /** Only if PayPal has been used previously for checkout flow AND PayPal payment instrument is saved in current cart */
        isPayPalFlow(cart.value, payPalCheckoutFlowInProgress.value)
      ) {
        /** Check if PayPal is available on payment page */
        const payPalMethod = paymentMethodsData.value.find(
          (payment) => payment.code === PaymentMethodCode.PAYPAL
        );

        if (payPalMethod?.code) {
          paymentMethod.value = payPalMethod.code;
        }
      } else {
        if (
          paymentInstruments.value.cards.length > 0 &&
          defaultPaymentInstrument.value
        ) {
          savedCreditCard.value =
            defaultPaymentInstrument.value.card.paymentInstrumentId;
          setCreditCardPaymentMethod();
        } else {
          paymentMethod.value = paymentMethodsData.value[0]?.code;
          savedCreditCard.value = '';
          showAddNewCreditCardForm.value = !theme.showMethodVariantsSeparately;
        }

        /** Unselect Apple Pay if it's a first option (default) and it's not available in current device */
        if (!isApplePayAvailable() || !theme.isApplePayDefault) {
          unselectApplePayAsDefault();
        }

        await handleKlarnaBeforeMount();
      }
    });

    onUnmounted(() => {
      paymentMethodGtmUnwatch();
      paymentMethod.value = '';
    });

    // total order value

    const orderTotal = computed(() => {
      const { remainingToPay, total } = cart.value.totals;
      return remainingToPay ?? total;
    });

    watch(orderTotal, (val) => {
      handleAmountToPayUpdate(val);
    });

    // end total order value

    return {
      theme,
      isVisible,
      renderVariant,
      paymentFormAccordion,
      showAddNewCreditCardForm,
    };
  },
});
