var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"vf-change-password",attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();}}},[_c('VfHeading',{staticClass:"vf-change-password__heading",attrs:{"level":4,"title":_vm.heading,"title-modifier":_vm.ui.headingTitleModifier,"subtitle":_vm.subheading,"subtitle-modifier":_vm.ui.subtitleModifierVariantA}}),_vm._v(" "),_c('VfRow',{staticClass:"vf-change-password__row"},[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12"}},[(_vm.showOldPasswordInput)?_c('VfInput',{staticClass:"vf-input--white-background vf-change-password__input",attrs:{"data-testid":"change-password__old-password__input","type":"password","label":_vm.oldPasswordLabel,"name":("" + _vm.oldPasswordLabel + _vm.heading),"required":"","valid":!_vm.$v.oldPassword.$error,"error-message":!_vm.$v.oldPassword.required
            ? _vm.validationMessages.required.replace(
                '{{fieldName}}',
                _vm.oldPasswordLabel.toLowerCase()
              )
            : _vm.validationMessages.oldPassword,"autocomplete":"off"},on:{"blur":function($event){return _vm.$v.oldPassword.$touch()}},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}):_vm._e(),_vm._v(" "),_c('VfInput',{staticClass:"vf-input--white-background vf-change-password__input",attrs:{"data-testid":"change-password__password__input","type":"password","label":_vm.passwordLabel,"name":("" + _vm.passwordLabel + _vm.heading),"required":"","valid":!_vm.$v.password.$error,"error-message":!_vm.$v.password.required
            ? _vm.validationMessages.required.replace(
                '{{fieldName}}',
                _vm.passwordLabel.toLowerCase()
              )
            : _vm.validationMessages.password,"autocomplete":"off"},on:{"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('VfInput',{staticClass:"vf-input--white-background vf-change-password__input",attrs:{"data-testid":"change-password__repeat-password__input","type":"password","label":_vm.confirmPasswordLabel,"name":_vm.confirmPasswordLabel,"required":"","valid":!_vm.$v.repeatPassword.$error,"error-message":!_vm.$v.repeatPassword.required
            ? _vm.validationMessages.required.replace(
                '{{fieldName}}',
                _vm.confirmPasswordLabel.toLowerCase()
              )
            : _vm.validationMessages.confirmPassword,"autocomplete":"off"},on:{"blur":function($event){return _vm.$v.repeatPassword.$touch()}},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"vf-change-password__action-buttons"},[_vm._t("confirm-button",function(){return [_c('VfButton',{class:[_vm.ui.submitButtonClassVariantA],attrs:{"data-testid":"change-password__submit__btn","disabled":_vm.disableSubmit},on:{"click":_vm.changePassword}},[_vm._v("\n        "+_vm._s(_vm.changePasswordButton)+"\n      ")])]},null,{ changePasswordButton: _vm.changePasswordButton, changePassword: _vm.changePassword }),_vm._v(" "),_vm._t("cancel-button",function(){return [(_vm.showCancelButton)?_c('VfButton',{class:_vm.ui.cancelButtonClass,attrs:{"link":_vm.cancelButtonLink}},[_vm._v("\n        "+_vm._s(_vm.cancelButton)+"\n      ")]):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }