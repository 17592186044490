



































import type {
  PasswordValidationRule,
  ValidationSteps,
} from '@vf/shared/src/utils/helpers';

import { computed, defineComponent, PropType } from '@vue/composition-api';
import { matchPasswordRule } from '@vf/shared/src/utils/helpers';
import useRootInstance from '../../theme/shared/useRootInstance';

export default defineComponent({
  name: 'PasswordHelper',
  props: {
    columns: {
      type: Number as PropType<number>,
      default: 1,
    },
    password: {
      type: String as PropType<string>,
      default: '',
    },
    passwordHelperText: {
      type: String as PropType<string>,
      default: '$Password must contain:$',
    },
    validationSteps: {
      type: Object as PropType<ValidationSteps>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const ui = {
      successIcon: 'correct',
      errorIcon: 'error',
      ...(root.$themeConfig?.passwordHelper || {}),
    };

    const validations = computed(() =>
      Object.entries(props.validationSteps).map(
        ([rule, text]: [PasswordValidationRule, string]) => ({
          isMatched: matchPassword(rule),
          rule,
          text,
        })
      )
    );

    const matchPassword = (rule: PasswordValidationRule): boolean =>
      matchPasswordRule(props.password, rule);

    const areAllRulesMatched = computed<boolean>(() =>
      Object.keys(props.validationSteps).every(matchPassword)
    );

    return {
      areAllRulesMatched,
      ui,
      validations,
    };
  },
});
